import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Leistungen } from "../src/gatsby-theme-docz/components/Leistungen/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "wir-erfüllen-ihre-träume"
    }}>{`Wir erfüllen Ihre Träume.`}</h1>
    <p>{`Berger Haustechnik ist seit über 100 Jahren ihr vertrauensvoller und zuverlässiger Partner in der Region. Wir stehen Ihnen mit großem Erfahrungsschatz, etlichen zufriedenen Kunden und stets höchster Qualität zur Seite. Unsere Leistungen beinhalten Lieferungen, Installation und Montage von Heizungs-, Lüftungs- und Klimaanlagen.`}</p>
    <p>{`Wir bieten zusätzlich Sanitärinstallationen an, sowie die Badeinrichtung und -... Für die Planung und Beratung Ihrer Projekte stehen wir Ihnen gerne stets zur Seite und unterstützen Sie Kompetenz. Unsere Leistungen beinhalten Lieferungen, Installation und Montage von Heizungs-, Lüftungs- und Klimaanlagen. Wir bieten zusätzlich Sanitärinstallationen an, sowie die Badeinrichtung und -... Für die Planung und Beratung Ihrer Projekte stehen wir Ihnen gerne stets zur Seite und unterstützen Sie Kompetenz.`}</p>
    <p>{`Für mehr Informationen zu unseren Leistungen klicken Sie einen der Bälle! `}{`▼`}</p>
    <Leistungen mdxType="Leistungen" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      